body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(33, 100, 169, 0.15);
    border-radius: 2px;
}

input[type=checkbox] {
    accent-color: #8441FF;
}

input[type=radio] {
    accent-color: #8441FF;
}

.react-dadata__input:focus {
    box-shadow: none;
}

:root {
    --index: calc(1vw + 1vh)
}

